<style lang="scss">
.attachments-table {
  table {
    tbody td {
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div class="attachments-table">
    <div v-if="localLoading" class="loading-spinner">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-data-table
      v-if="viewMode === 'list'"
      :headers="headers"
      :items="localAttachments"
      :options.sync="options"
      :loading="localLoading"
      @update:options="handlePagination"
      :server-items-length="totalAttachments"
      @click:row="handleClickAttachment"
      show-select
      :value="value"
      @input="$emit('input', $event)"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, -1],
      }"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.xs"
    >
      <template v-slot:header.category_description="{ header }">
        <span>
          {{ __("categoria", "documents") }}
          <v-badge
            color="error"
            dot
            overlap
            bordered
            :value="categoryFilter.length"
            class="mx-1"
          >
            <v-btn @click="openCategoryDialog" small icon>
              <v-icon small>mdi-filter-menu</v-icon>
            </v-btn>
          </v-badge>
        </span>
      </template>

      <template v-slot:item.preview="{ item }">
        <AttachmentAvatar :attachment="item" />
      </template>

      <template v-slot:item.category_description="{ item }">
        <v-chip
          @click.stop="handleClickCategory(item)"
          v-if="item.category_id"
          small
        >
          {{ item.category_description }}
        </v-chip>
        <v-chip @click.stop="handleClickCategory(item)" v-else small outlined>
          {{ $capitalize($tc("model.documents_no_categories")) }}
        </v-chip>
      </template>

      <template v-slot:item.name="{ item }">
        <span :class="{ 'info--text': item.has_notifications_to_manager }">
          {{ truncateIfWordExceedsLength(item.name, 30) }}
        </span>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at }}
      </template>

      <template v-slot:item.size="{ item }">
        {{ getFileSize(item) }}
      </template>

      <template v-slot:item.external_reference="{ item }">
        <AttachmentVisibility
          :attachment="item"
          @change="handleChangeVisibility(item, $event)"
        ></AttachmentVisibility>
      </template>
    </v-data-table>

    <div v-else>
      <PerCategoryTab
        ref="perCategory"
        @change:visibility="handleChangeVisibility"
        @click:attachment="handleClickAttachment"
        :modelValue="value"
        @update:modelValue="handleUpdateValue"
      ></PerCategoryTab>
    </div>

    <attachment-category-filter-dialog
      v-model="categoryDialog"
      :categories="folders"
      :filter="localCategoryFilter"
      @change:filter="handleCategoryFilterChange"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="!$vuetify.breakpoint.xs ? '800px' : undefined"
    />
    <attachment-category-dialog
      ref="categoryDialog"
      :categories="folders"
      :loading="categoryLoading"
      @submit="handleUpdateCategory"
      @submit:multiple="handleMultipleUpdateCategory"
    />
    <file-dialog
      v-model="file_preview"
      :attachment="file"
      @updateFile="updateFile"
      @deleteFile="deleteFile"
      @refreshAttachments="refreshCategoryAttachments"
    />
    <attachment-change-visibility-dialog
      v-model="visibilityDialog"
      :entity="visibilityEntity"
      :file="visibilityFile"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="!$vuetify.breakpoint.xs ? '600px' : undefined"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import AttachmentsMixin from "@/mixins/AttachmentsMixin";
import i18n from "@/mixins/i18n";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import AttachmentCategoryFilterDialog from "@/components/Attachment/AttachmentCategoryFilterDialog";
import AttachmentCategoryDialog from "@/components/Lead/AttachmentCategoryDialog";
import AttachmentChangeVisibilityDialog from "@/components/Attachment/AttachmentChangeVisibilityDialog";
import AttachmentVisibility from "./AttachmentTab/AttachmentVisibility.vue";
import {
  indexPaginated,
  update,
  multipleUpdateCategory,
  getAttachmentsByCategory,
} from "@/services/attachment";
import data_table from "@/tools/data_table";
import FileDialog from "../FileDialog.vue";
import {
  index as indexCategories,
  getCategoriesWithAttachments,
} from "@/services/attachment_category";
import PerCategoryTab from "@/components/Lead/AttachmentTab/PerCategoryTab.vue";
import AttachmentAvatar from "@/components/Lead/AttachmentTab/AttachmentAvatar.vue";
export default {
  name: "attachments-table",

  mixins: [AttachmentsMixin, i18n, ToastsMixin],

  components: {
    AttachmentCategoryFilterDialog,
    AttachmentCategoryDialog,
    AttachmentChangeVisibilityDialog,
    FileDialog,
    PerCategoryTab,
    AttachmentVisibility,
    AttachmentAvatar,
  },

  props: {
    group: {
      type: Boolean,
      default: () => false,
    },
    research: String,
    viewMode: String,
    attachments: Array,
    categories: Array,
    categoryFilter: Array,
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      localCategoryFilter: [...this.categoryFilter],
      search: "",
      folders: [],
      files: [],
      file: {},
      file_preview: false,
      currentFile: null,
      filePreviewDialog: false,
      showFileDialog: false,
      categoriesWithDocs: [],
      categoryLoading: false,
      localLoading: this.loading,
      localAttachments: [],
      totalAttachments: 0,
      totalAttachmentsCategory: 0,
      visibilityDialog: false,
      visibilityEntity: null,
      visibilityFile: null,
      updating_visibility_insurer: false,
      updating_visibility_unity: false,
      selectedCategory: null,
      lastCategoryParams: null,
      i18n_prefix: "documents",
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
      },
      category: null,
      categoryDialog: false,
      isFetchingFiles: false,
      hasFetchedFiles: false,
      headers: [
        {
          text: "",
          value: "preview",
          sortable: false,
          align: "center",
          width: "65",
          showInCategory: true,
        },
        {
          text: this.__("arquivos", "documents"),
          value: "name",
        },
        {
          text: this.__("extensão", "documents"),
          value: "extension",
        },
        {
          text: this.__("categoria", "documents"),
          value: "category_description",
          width: "160",
          showInCategory: false,
        },
        {
          text: this.__("visibilidade", "documents"),
          value: "external_reference",
          width: "110",
        },
        {
          text: this.__("data de criação", "documents"),
          value: "created_at",
          width: "150",
        },
      ],
    };
  },

  methods: {
    handleUpdateValue(value) {
      this.$emit("input", value);
    },
    filterHeaders(headers) {
      return headers.filter((header) => header.showInCategory !== false);
    },
    async handleMultipleUpdateCategory(payload) {
      const { files, category_id } = payload;

      this.categoryLoading = true;
      try {
        const response = await multipleUpdateCategory(
          category_id,
          files.map((file) => file.id)
        );
        const newFiles = response.attachments;

        this.files = this.files.map((file) => {
          const newFile = newFiles.find((newFile) => newFile.id === file.id);

          if (!newFile) return file;

          return {
            ...file,
            category: newFile.category,
            category_id: newFile.category_id,
            is_locked: newFile.is_locked,
          };
        });

        this.$refs.categoryDialog.close();
        this.selectedFiles = [];
        this.$store.commit(
          "alert",
          `${newFiles.length} ` +
            this.$capitalize(
              this.$tc("model.documents_categorized_attachments_alert")
            )
        );
        this.refreshAttachments();
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.categoryLoading = false;
      }
    },
    refreshAttachments() {
      if (this.viewMode === "categories") {
        return this.$refs.perCategory.refreshAll();
      }
      this.hasFetchedFiles = false;
      return this.getFiles();
    },
    refreshCategoryAttachments(attachment) {
      if (this.viewMode === "categories") {
        return this.$refs.perCategory.refreshCategory(attachment.category_id);
      }
      this.hasFetchedFiles = false;
      return this.getFiles();
    },
    handleChangeVisibility(file, entity) {
      this.visibilityDialog = true;
      this.visibilityFile = file;
      this.visibilityEntity = entity;
      const visibilities = this.getVisibilities(file);
    },
    handleClickFile(item) {
      this.currentFile = item;
      this.filePreviewDialog = true;
    },
    previewFile(file) {
      this.file = file;
      this.showFileDialog = true;
      this.file_preview = true;
    },
    handleCategoryFilterChange(selectedCategories) {
      this.localCategoryFilter = selectedCategories;
      this.refreshAttachments();
    },
    async downloadFile(file) {
      window.open(file.public_url);
    },
    open_file_preview(file) {
      this.file_preview = true;
      this.file = { ...file };
    },
    handleClickAttachment(attachment) {
      this.file = attachment;
      this.file_preview = true;
    },
    deleteFile(file_deleted) {
      this.files = this.files.filter((file) => {
        return file.id !== file_deleted;
      });
      this.localAttachments = this.localAttachments.filter((file) => {
        return file.id !== file_deleted;
      });
      this.file_preview = false;
    },
    updateFile(file_changed) {
      this.file_preview = false;
    },
    openFilesDialog() {
      this.$refs.file.click();
    },

    handleClickCategory(file) {
      this.getFolders();
      this.$refs.categoryDialog.init(file);

      // Chama getAttachmentsByCategory com o filtro de pesquisa.
      this.getAttachmentsByCategory(file);
    },

    async handleUpdateCategory(payload) {
      const { file, category_id } = payload;

      this.categoryLoading = true;
      try {
        const response = await update({ category_id }, file.id);

        this.files = this.files.map((item) => {
          if (item.id == file.id) {
            return {
              ...item,
              category: response.category,
              category_id: response.category_id,
              is_locked: response.is_locked,
            };
          }
          return item;
        });

        this.$refs.categoryDialog.close();
        this.refreshAttachments();
      } catch (error) {
        this.$store.commit(
          "error",
          this.$capitalize(
            this.$tc("model.documents_failed_change_category_alert")
          )
        );
      } finally {
        this.categoryLoading = false;
      }
    },
    getFileSize(item) {
      return (item.size / 1024 / 1024).toFixed(2) + " MB";
    },
    openCategoryDialog() {
      this.getFolders();
      this.categoryDialog = true;
    },
    async getFolders() {
      if (this.folders.length === 0) {
        this.categoryLoading = true;
        try {
          this.folders = await indexCategories();
        } catch (error) {
          this.toastError(
            this.__("erro ao carregar categorias:", "documents"),
            error
          );
        } finally {
          this.categoryLoading = false;
        }
      }
    },
    handleSubmit() {
      if (this.viewMode === "list") {
        return this.getFiles();
      }
      return this.getAttachmentsByCategory(this.visibilityFile.category_id);
    },
    async fetchCategoriesWithDocs() {
      try {
        const response = await getCategoriesWithAttachments(this.lead.id, {
          filter: this.research,
        });
        this.categoriesWithDocs = response.data.map((category) => ({
          ...category,
          isOpen: false,
          attachments: [],
          loading: false,
        }));
      } catch (error) {
        this.toastError(
          this.__("erro ao buscar categorias com documentos:", "documents"),
          error
        );
        this.categoriesWithDocs = [];
      }
    },
    toggleCategory(category) {
      this.selectedCategory = category;

      // Se a categoria já está carregada, apenas abrir/fechar
      if (category.isOpen) {
        category.isOpen = false;
        return;
      }

      // Se já foi carregado antes, apenas abre
      if (category.attachments.length > 0) {
        category.isOpen = true;
        return;
      }

      // Se ainda não foi carregado, busca os dados
      category.isOpen = true;
      category.loading = true;
      this.getAttachmentsByCategory(category);
    },
    async getAttachmentsByCategory(categoryId) {
      if (!categoryId) {
        this.$refs.perCategory.refreshCategory(null);
        return;
      }
      this.$refs.perCategory.refreshCategory(categoryId);
    },
    async getFiles() {
      if (!this.lead || !this.lead.id || this.isFetchingFiles) return;

      this.isFetchingFiles = true;
      this.localLoading = true;

      try {
        const response = await indexPaginated(this.lead.id, {
          ...data_table.getParams(this.options, this.research),
          filter: this.research,
          categories: this.localCategoryFilter,
        });

        this.localAttachments = response.data || [];
        this.totalAttachments = response.meta.total;
        this.hasFetchedFiles = true;
      } catch (error) {
        this.toastError(
          this.__("erro ao buscar arquivos:", "documents"),
          error
        );
        this.localAttachments = [];
        this.totalAttachments = 0;
      } finally {
        this.localLoading = false;
        this.isFetchingFiles = false;
      }
    },

    handleCategorizeFiles(selectedFiles) {
      this.getFolders();
      this.$refs.categoryDialog.initMultiple(selectedFiles);
    },
    getVisibilityColor(visible, color) {
      return visible ? color : "blue-grey lighten-4";
    },
    getVisibilityTooltip(visible, entity) {
      switch (entity) {
        case "unity":
          return visible
            ? this.__("Visível para unidade")
            : this.__("Invisível para unidade");
        case "insurer":
          return visible
            ? this.__("Visível para companhia")
            : this.__("Invisível para companhia");
      }
    },
    getVisibilities(item) {
      return [
        {
          entity: "insurer",
          color: this.getVisibilityColor(item.visible_to_insurer, "cyan"),
          tooltip: this.getVisibilityTooltip(
            item.visible_to_insurer,
            "insurer"
          ),
          icon: "mdi-domain",
          is_locked: item.is_locked,
        },

        {
          entity: "unity",
          color: this.getVisibilityColor(item.visible_to_unity, "indigo"),
          tooltip: this.getVisibilityTooltip(item.visible_to_unity, "unity"),
          icon: "mdi-account-hard-hat",
          is_locked: false,
        },
      ];
    },
    truncateIfWordExceedsLength(string, maxWordLength) {
      let words = string.split(/[\s-]/);

      for (let word of words) {
        if (word.length > maxWordLength) {
          return string.substring(0, maxWordLength) + "...";
        }
      }
      return string;
    },
    handlePagination(newOptions, category) {
      if (this.viewMode === "categories") {
        category.paginationOptions = { ...newOptions };
        this.getAttachmentsByCategory(category);
        return;
      }

      this.options = { ...this.options, ...newOptions };
      this.hasFetchedFiles = false;
      this.getFiles();
    },
  },

  watch: {
    lead: {
      handler(newLead) {
        if (newLead?.id) {
          this.getFiles();
        }
      },
      immediate: true,
      deep: true,
    },
    categoryFilter(value) {
      this.localCategoryFilter = [...value];
      this.options = {
        ...this.options,
        page: 1,
      };
    },
    loading(newVal) {
      this.localLoading = newVal;
    },
    attachments(newVal) {
      this.localAttachments = newVal;
    },
    viewMode(newVal) {
      if (newVal === "categories") {
        this.fetchCategoriesWithDocs();
      }
      if (newVal === "list" && !this.hasFetchedFiles) {
        return this.getFiles();
      }
    },
  },

  computed: {
    groupBy() {
      return this.group === true ? ["category.description"] : [];
    },
    lead() {
      return this.$store.getters.getLead;
    },
    insurer_portal_companhia() {
      return this.lead.insurer.portal_companhia;
    },
  },

  created() {
    if (this.viewMode === "categories") {
      this.fetchCategoriesWithDocs();
    }
  },
};
</script>

<template>
  <div>
    <AttachmentCategoryCard
      v-for="category in categoriesWithDocs"
      :key="category.category_id"
      :category="category"
      :leadId="lead.id"
      :ref="getCategoryRef(category)"
      @change:visibility="handleChangeVisibility"
      @click:attachment="handleClickAttachment"
      :modelValue="modelValue"
      @update:modelValue="handleUpdate"
    ></AttachmentCategoryCard>
  </div>
</template>

<script>
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import {
  indexPaginated,
  update,
  multipleUpdateCategory,
  getAttachmentsByCategory,
} from "@/services/attachment";
import {
  index as indexCategories,
  getCategoriesWithAttachments,
} from "@/services/attachment_category";
import AttachmentsMixin from "@/mixins/AttachmentsMixin";
import i18n from "@/mixins/i18n";
import AttachmentCategoryCard from "./AttachmentCategoryCard.vue";

export default {
  mixins: [AttachmentsMixin, ToastsMixin, i18n],

  components: {
    AttachmentCategoryCard,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  data() {
    return {
      categoriesWithDocs: [],
      headers: [
        {
          text: "",
          value: "preview",
          sortable: false,
          align: "center",
          width: "65",
          showInCategory: true,
        },
        {
          text: this.__("arquivos", "documents"),
          value: "name",
        },
        {
          text: this.__("extensão", "documents"),
          value: "extension",
        },
        {
          text: this.__("categoria", "documents"),
          value: "category_description",
          width: "160",
          showInCategory: false,
        },
        {
          text: this.__("visibilidade", "documents"),
          value: "external_reference",
          width: "110",
        },
        {
          text: this.__("data de criação", "documents"),
          value: "created_at",
          width: "150",
        },
      ],
    };
  },

  methods: {
    handleUpdate(value) {
      this.$emit("update:modelValue", value);
    },

    getCategoryRef(category) {
      if (!category) {
        return "category.null";
      }

      return `category.${category.category_id}`;
    },
    async refreshCategory(categoryId) {
      if (categoryId === null) {
        await this.$refs[this.getCategoryRef(null)][0].refresh();
        return;
      }

      const category = this.categoriesWithDocs.find((item) => {
        return item.category_id === categoryId;
      });

      if (!category) {
        return;
      }

      await this.$refs[this.getCategoryRef(category)][0].refresh();
    },
    async refreshAll() {
      await this.fetchCategoriesWithDocs();

      this.categoriesWithDocs.forEach(async (category) => {
        await this.$refs[this.getCategoryRef(category)][0].refresh();
      });
    },
    handleChangeVisibility(attachment, visibility) {
      this.$emit("change:visibility", attachment, visibility);
    },
    handleClickAttachment(attachment) {
      this.$emit("click:attachment", attachment);
    },
    handleClickCategory(file) {
      this.getFolders();
      this.$refs.categoryDialog.init(file);

      // Chama getAttachmentsByCategory com o filtro de pesquisa.
      this.getAttachmentsByCategory(file);
    },
    getFileSize(item) {
      return (item.size / 1024 / 1024).toFixed(2) + " MB";
    },
    truncateIfWordExceedsLength(string, maxWordLength) {
      let words = string.split(/[\s-]/);

      for (let word of words) {
        if (word.length > maxWordLength) {
          return string.substring(0, maxWordLength) + "...";
        }
      }
      return string;
    },
    getVisibilities(item) {
      return [
        {
          entity: "insurer",
          color: this.getVisibilityColor(item.visible_to_insurer, "cyan"),
          tooltip: this.getVisibilityTooltip(
            item.visible_to_insurer,
            "insurer"
          ),
          icon: "mdi-domain",
          is_locked: item.is_locked,
        },

        {
          entity: "unity",
          color: this.getVisibilityColor(item.visible_to_unity, "indigo"),
          tooltip: this.getVisibilityTooltip(item.visible_to_unity, "unity"),
          icon: "mdi-account-hard-hat",
          is_locked: false,
        },
      ];
    },
    handlePagination(newOptions, category) {
      category.paginationOptions = { ...newOptions };
      this.getAttachmentsByCategory(category);
      return;
    },
    filterHeaders(headers) {
      return headers.filter((header) => header.showInCategory !== false);
    },
    async fetchCategoriesWithDocs() {
      try {
        const response = await getCategoriesWithAttachments(this.lead.id, {
          filter: this.research,
        });
        this.categoriesWithDocs = response.data.map((category) => ({
          ...category,
          isOpen: false,
          attachments: [],
          loading: false,
        }));
      } catch (error) {
        console.error(error);
        this.toastError(
          this.__("erro ao buscar categorias com documentos:", "documents"),
          error
        );
        this.categoriesWithDocs = [];
      }
    },
    toggleCategory(category) {
      // Se a categoria já está carregada, apenas abrir/fechar
      if (category.isOpen) {
        category.isOpen = false;
        return;
      }

      // Se já foi carregado antes, apenas abre
      if (category.attachments.length > 0) {
        category.isOpen = true;
        return;
      }

      // Se ainda não foi carregado, busca os dados
      category.isOpen = true;
      category.loading = true;
      this.getAttachmentsByCategory(category);
    },
  },

  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
  },

  created() {
    this.fetchCategoriesWithDocs();
  },
};
</script>

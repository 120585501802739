import Vue from "vue";

export async function index(params = {}) {
  const response = await Vue.prototype.$http.get(`/attachments_categories`, {
    params,
  });

  return response.data;
}

export async function getCategoriesWithAttachments(lead_id, params = {}) {
  const response = await Vue.prototype.$http.get(
    `/leads/${lead_id}/attachment_categories`,
    { params }
  );
  return response.data;
}

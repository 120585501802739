<template>
  <v-dialog :value="value" @change="handleChange" persistent v-bind="$attrs">
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ $capitalize($tc("model.documents_filter_category")) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form @submit.prevent="submit">
        <v-card-text class="pt-2">
          <v-row dense>
            <v-col class="text-left">
              <v-btn
                color="primary"
                small
                text
                @click="
                  changeCategoryFilter([
                    ...categories.map((item) => item.id),
                    0,
                  ])
                "
              >
                {{ $capitalize($tc("model.documents_all_header")) }}
              </v-btn>
              <v-btn
                color="secondary"
                small
                text
                @click="changeCategoryFilter([])"
                class="ml-1"
              >
                {{ $capitalize($tc("model.documents_clean_button")) }}
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text small color="primary" type="submit">
                <v-icon small>mdi-filter</v-icon>
                {{ $capitalize($tc("model.documents_filter_button")) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-chip-group
            column
            multiple
            v-model="localFilter"
            active-class="primary--text"
            class="mt-2"
          >
            <v-virtual-scroll
              :bench="1"
              :items="chunked_chips"
              :item-height="40"
              height="400"
            >
              <v-chip :value="0">
                {{ $capitalize($tc("model.documents_no_categories")) }}
              </v-chip>
              <template v-slot="{ item }">
                <v-chip
                  v-for="category in item"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.description }}
                </v-chip>
              </template>
            </v-virtual-scroll>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "attachment-category-filter-dialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFilter: [],
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    close() {
      this.localFilter = [];
      this.$emit("input", false);
    },
    cancel() {
      this.close();

      if (this.$listeners.cancel) {
        this.$listeners.cancel();
      }
    },
    submit() {
      this.$emit("change:filter", this.localFilter);
      this.close();
    },

    changeCategoryFilter(filters) {
      this.localFilter = filters;
    },
  },
  computed: {
    itemsPerLine: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
      }

      return 4;
    },
    chunked_chips: function () {
      const orderedCategories = this.categories;
      orderedCategories.sort((value, nextValue) => nextValue.id - value.id);

      const groupsOfCategories = [];

      for (let i = 0; i < orderedCategories.length; i += this.itemsPerLine) {
        groupsOfCategories.push(
          orderedCategories.slice(i, i + this.itemsPerLine)
        );
      }
      return groupsOfCategories;
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.localFilter = this.filter;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-card :loading="loading" outlined>
      <v-list-item @click="toggleOpen()" style="background-color: #f1f1f1">
        <v-list-item-content>
          <v-list-item-subtitle style="color: #333">
            <v-icon left v-if="open">mdi-folder-open</v-icon>
            <v-icon left v-else>mdi-folder</v-icon>
            {{
              category.description ||
              $capitalize($tc("model.documents_no_categories"))
            }}
            ({{ category.attachments_count }})
            <v-btn @click.stop="refresh()" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-if="!open">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-simple-table v-if="open">
        <thead>
          <tr>
            <th>
              <v-simple-checkbox
                @input="toogleSelectAll()"
                :value="allAttachmentsSelected"
                :indeterminate="indeterminatedAttachmentsSelected"
              ></v-simple-checkbox>
            </th>
            <th></th>
            <th>arquivos</th>
            <th>extensão</th>
            <th width="100">visibilidade</th>
            <th>data de criação</th>
          </tr>
        </thead>
        <tbody>
          <tr
            @click="handleClickAttachment(attachment)"
            v-for="attachment in attachments"
            :key="attachment.id"
          >
            <td>
              <v-simple-checkbox
                @input="handleInput(attachment)"
                :value="attachmentIsSelected(attachment)"
              ></v-simple-checkbox>
            </td>
            <td>
              <AttachmentAvatar :attachment="attachment" />
            </td>
            <td v-text="attachment.name"></td>
            <td v-text="attachment.extension"></td>
            <td>
              <AttachmentVisibility
                @change="handleChangeVisibility(attachment, $event)"
                :attachment="attachment"
              />
            </td>
            <td v-text="attachment.created_at"></td>
          </tr>
        </tbody>
        <tfoot v-if="!loading && page < lastPage">
          <tr>
            <td colspan="6" class="text-center">
              <v-btn @click="loadFiles(page + 1)" text color="primary" small>
                Carregar mais
              </v-btn>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-card-text v-if="loading">
        <v-skeleton-loader type="table-row"></v-skeleton-loader>
        <v-skeleton-loader type="table-row"></v-skeleton-loader>
        <v-skeleton-loader type="table-row"></v-skeleton-loader>
        <v-skeleton-loader type="table-row"></v-skeleton-loader>
        <v-skeleton-loader type="table-row"></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import { getAttachmentsByCategory } from "@/services/attachment";
import AttachmentVisibility from "./AttachmentVisibility.vue";
import AttachmentAvatar from "./AttachmentAvatar.vue";

export default {
  mixins: [i18n],

  components: {
    AttachmentVisibility,
    AttachmentAvatar,
  },

  props: {
    category: Object,
    leadId: Number,
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  data() {
    return {
      open: false,
      loading: false,
      loaded: false,

      attachments: [],

      page: 1,
      lastPage: 0,
      perPage: 20,
    };
  },

  methods: {
    async selectAllAttachments() {
      const newValue = [...this.modelValue];
      this.attachments.forEach((attachment) => {
        if (!this.attachmentIsSelected(attachment)) {
          newValue.push(attachment);
        }
      });

      this.$emit("update:modelValue", newValue);
    },
    async unselectAllAttachments() {
      const attachmentIds = this.attachments.map((a) => a.id);

      this.$emit(
        "update:modelValue",
        this.modelValue.filter((item) => !attachmentIds.includes(item.id))
      );
    },

    toogleSelectAll() {
      if (this.allAttachmentsSelected) {
        this.unselectAllAttachments();
        return;
      }

      this.selectAllAttachments();
    },

    attachmentIsSelected(attachment) {
      return !!this.modelValue.find((item) => item.id === attachment.id);
    },

    selectAttachment(attachment) {
      if (this.attachmentIsSelected(attachment)) {
        return;
      }

      this.$emit("update:modelValue", this.modelValue.concat(attachment));
    },

    unselectAttachment(attachment) {
      this.$emit(
        "update:modelValue",
        this.modelValue.filter((item) => item.id !== attachment.id)
      );
    },

    handleInput(attachment) {
      if (this.attachmentIsSelected(attachment)) {
        this.unselectAttachment(attachment);
        return;
      }

      this.selectAttachment(attachment);
    },

    handleClickAttachment(attachment) {
      this.$emit("click:attachment", attachment);
    },

    handleChangeVisibility(attachment, visibility) {
      this.$emit("change:visibility", attachment, visibility);
    },

    toggleOpen() {
      this.open = !this.open;
    },

    filterHeaders(headers) {
      return headers.filter((header) => header.showInCategory !== false);
    },

    async loadFiles(page = 1) {
      if (page === 1) {
        this.attachments = [];
      }
      this.loading = true;
      try {
        const response = await getAttachmentsByCategory(
          this.leadId,
          this.category.category_id,
          {
            sortBy: "created_at",
            sortDesc: true,
            page,
            itemsPerPage: this.perPage,
            filter: "",
          }
        );
        this.attachments = this.attachments.concat(response.data);
        this.loaded = true;
        this.page = page;
        this.lastPage = response.meta.last_page;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async refresh() {
      if (!this.loaded) {
        return;
      }

      const lastPage = this.page;

      for (let currentPage = 1; currentPage <= lastPage; currentPage++) {
        await this.loadFiles(currentPage);
      }
    },
  },

  computed: {
    totalSelected() {
      return this.attachments.reduce(
        (accumulator, currentValue) =>
          accumulator + (this.attachmentIsSelected(currentValue) ? 1 : 0),
        0
      );
    },

    allAttachmentsSelected() {
      //   const attachment = this.attachments.find((attachment) => {
      //     return !this.attachmentIsSelected(attachment);
      //   });

      //   return attachment === undefined;

      // },
      if (this.attachments.length === 0) {
        return false;
      }
      return this.totalSelected === this.attachments.length;
    },

    indeterminatedAttachmentsSelected() {
      return (
        this.totalSelected > 0 && this.totalSelected < this.attachments.length
      );
    },
  },

  watch: {
    open(value) {
      if (this.loaded) {
        return;
      }

      if (value) {
        this.loadFiles();
      }
    },
  },
};
</script>

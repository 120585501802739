<template>
  <div class="d-flex">
    <div
      class="col pl-1"
      v-for="visibility in getVisibilities()"
      :key="visibility.entity"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-badge
            color="grey"
            overlap
            icon="mdi-lock"
            :value="visibility.is_locked"
          >
            <v-avatar
              :color="visibility.color"
              size="30"
              v-on="on"
              @click.prevent.stop="handleChangeVisibility(visibility.entity)"
            >
              <v-icon dark small>{{ visibility.icon }}</v-icon>
            </v-avatar>
          </v-badge>
        </template>
        <span>{{ visibility.tooltip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],

  components: {},

  props: {
    attachment: Object,
  },

  data() {
    return {};
  },

  methods: {
    getVisibilities() {
      return [
        {
          entity: "insurer",
          color: this.getVisibilityColor(
            this.attachment.visible_to_insurer,
            "cyan"
          ),
          tooltip: this.getVisibilityTooltip(
            this.attachment.visible_to_insurer,
            "insurer"
          ),
          icon: "mdi-domain",
          is_locked: this.attachment.is_locked,
        },

        {
          entity: "unity",
          color: this.getVisibilityColor(
            this.attachment.visible_to_unity,
            "indigo"
          ),
          tooltip: this.getVisibilityTooltip(
            this.attachment.visible_to_unity,
            "unity"
          ),
          icon: "mdi-account-hard-hat",
          is_locked: false,
        },
      ];
    },
    getVisibilityColor(visible, color) {
      return visible ? color : "blue-grey lighten-4";
    },
    getVisibilityTooltip(visible, entity) {
      switch (entity) {
        case "unity":
          return visible
            ? this.__("Visível para unidade")
            : this.__("Invisível para unidade");
        case "insurer":
          return visible
            ? this.__("Visível para companhia")
            : this.__("Invisível para companhia");
      }
    },
    handleChangeVisibility(entity) {
      this.$emit("change", entity);
    },
  },

  computed: {},
};
</script>

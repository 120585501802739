<template>
  <v-avatar
    :color="getFilesIconColor(getFilesIcon(attachment)) || 'grey lighten-1'"
    tile
    class="white--text p-1"
    size="40"
  >
    <v-img v-if="isFileImage(attachment)" :src="attachment.public_url">
      <template v-slot:placeholder>
        <v-icon dark>mdi-image</v-icon>
      </template>
    </v-img>
    <v-icon v-else dark>
      {{ getFilesIcon(attachment) }}
    </v-icon>
  </v-avatar>
</template>

<script>
import AttachmentsMixin from "@/mixins/AttachmentsMixin";

export default {
  mixins: [AttachmentsMixin],

  components: {},

  props: {
    attachment: Object,
  },

  data() {
    return {};
  },

  methods: {},

  computed: {},
};
</script>
